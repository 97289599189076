@import url("https://unpkg.com/@tailwindcss/typography@0.4.x/dist/typography.min.css");
@import url("https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css");
@import url('https://fonts.googleapis.com/css2?family=Bona+Nova:wght@700&display=swap');

.cursive {
  font-family: 'Bona Nova', serif;
}

.bg {
  background-image: url('./logo.svg');
  background: rgb(129,70,255);
  background: linear-gradient(174deg, rgba(129,70,255,1) 0%, rgba(165,50,205,0.5842902861445782) 100%);
}

.prose img {
  margin-left: auto;
  margin-right: autho;
  border-radius: 5px;
}

.home-name {
  font-size: 4rem;
}

.background-shapes {
  height: 100%;
  width: 100%;
  background-image: url('./shapes_background.svg');
  background-position: center;
  background-repeat: no-repeat;
}

.text-shadow {
  text-shadow: 3px 3px grey;
}

.sphere {
  position: relative;
}